#ProjectsTitle {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}

#repoList {
    overflow-y: auto;
    max-height: 450px;
}

#repoList>div:nth-child(even){
    background-color: rgb(240, 240, 240);
}

#repoList>div:nth-child(odd){
    background-color: rgb(255, 255, 255);
}

#cont{
    background-color: white;
    height: 100vh;
}
