#aboutTitle {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}

#cont{
    background-color: white;
    height: 100vh;
    width: 100vw !important;
    margin-left: 0;
    margin-right: 0;
    
}
