#pj {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

/* #icons {
    padding-left: 3%;
    padding-right: 3%;
} */

