.App {
  text-align: center;
  background-color: rgb(8, 16, 34);

  font-family: 'Roboto', sans-serif;
  
}

#icons {
  padding-left: 3%;
  padding-right: 3%;
}